<template>
  <v-card class="" @click="viewEvent">
    <v-img
        :aspect-ratio="3/4"
        :src="event.image_path ? s3BucketURL + event.image_path : defaultImages.workshop"
        height="200"
    >
    </v-img>
    <div class="p-4">
      <h3 class="text-xl line-clamp-1">
        {{ event.name }}
      </h3>
      <p class="text-sm">
        <span class="text-light-gray">Date & Time: </span>  {{ formatEventTime() }}
      </p>
      <p class="text-sm line-clamp-1" >
        <span class="text-light-gray">Location: </span>{{ getLocation() }}
      </p>
      <v-divider class="m-y-3"/>
      <div class="row justify-content-center text-center">
        <div class="cols col-md-6 d-flex justify-content-center">
          <SvgIcon class="gap-x-4 m-b-3" v-if="checkReadPermission($modules.events.sales.slug)">
            <template v-slot:icon>
              <SalesIcon />
            </template>
            <div>
              <p class="m-b-0 font-medium">
                {{ Number(typeof event.sales != "undefined" ? event.sales : 0) }}
              </p>
              <p class="m-b-0 font-medium text-xs">
                Sales
              </p>
            </div>
          </SvgIcon>
        </div>
        <div class="cols col-md-6 d-flex justify-content-center">
          <SvgIcon class="gap-x-4 m-b-3">
            <template v-slot:icon>
              <AttendanceIcon />
            </template>
            <div>
              <p class="m-b-0 font-medium">
                {{ event.participants || 0 }} / {{ event.total_tickets || 0 }}
              </p>
              <p class="m-b-0 font-medium text-xs">
                Attendance
              </p>
            </div>
          </SvgIcon>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import SalesIcon from '@/assets/images/events/sales-icon.svg';
import AttendanceIcon from '@/assets/images/events/attendance-icon.svg';
import SvgIcon from "@/components/Image/SvgIcon.vue";
export default {
  components: {SvgIcon, SalesIcon, AttendanceIcon},
  props: {
    event: Object,
    defaultImages: { type: Object, default: ()=>{}},
  },
  data() {
    return {

    }
  },
  methods: {
    viewEvent(){
      this.$router.push({
        name: "EventsView",
        params: {data: btoa(this.event.id)},
      });
    },
    formatEventTime() {
      if(this.event.event_schedules && this.event.event_schedules.length){
        const startDate = this.$options.filters.dateformat(this.event.event_schedules[0].start_date);
        const startTime = this.$options.filters.timeFormat(this.event.event_schedules[0].start_time);
        const endTime = this.$options.filters.timeFormat(this.event.event_schedules[0].end_time);
        return `${startDate} ${startTime} to ${endTime}`;
      }
    },
    getLocation(){
      let loc = this.event.location;
      if(this.event.location == "External"){
        this.event.event_schedules.forEach((es) => {
          if(es.location){
            loc = es.location;
          }
        })
      }
      return loc;
    }
  }
}
</script>