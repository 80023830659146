<template>
  <v-card class="rounded-lg shadow" v-if="membersExpireThisMonth && membersExpireThisMonth.length" style="min-height: 270px;overflow: hidden;">
    <div>
      <div class="p-3 bg-ghost-white d-flex justify-space-between align-center">
        <h3 class="text-base font-semibold black-text">Expiry this Month</h3>
      </div>
      <table class="dash_tbl membership-da-table">
        <tbody>
        <tr v-for="(expMember,index) in membersExpireThisMonth" :key="index">
          <td class="text-left">
                      <span>
                        <div class="gap-x-2 d-flex align-center gap-x-1">
                          <v-avatar size="40px" rounded="0" v-if="expMember.profile_image">
                            <img :src="expMember.profile_image ? s3BucketURL + expMember.profile_image : images[defaultImage]" />
                          </v-avatar>
                           <div v-else class="v-avatar text-h5 font-weight-light white--text rounded teal" style="height: 40px; min-width: 40px; width: 40px;">{{expMember.customer_name[0]}}</div>
                          <span> {{ expMember.customer_name }} </span>
                        </div>
                      </span>
          </td>
          <td class="text-right text-black">{{ expMember.end_date | dateformatDDMMYYYY }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </v-card>
</template>
<script>
export default {
  props: {
    membersExpireThisMonth: { type: Array, default: ()=>[]},
    defaultImages: { type: Object, default: ()=>{}},
  },
}
</script>