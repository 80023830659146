<template>
  <v-container fluid style="padding: 1rem" >

    <v-row v-if="showChartHeadder">
      <v-col class="ml-3 mt-3" md="4">
        <date-range-field
            :dateType="dateToggle"
            @periodChange="dateChange"
            class-name="q-text-field shadow-0"
            :outlined="true"
            :dense="true"
        />


      </v-col>
      <v-spacer></v-spacer>
      <v-col md="3">
        <v-btn-toggle
            v-model="dateToggle"
            mandatory
            borderless
            class="q-tabs shadow-0 bordered"
            style="height: 40px !important;"
        >
          <v-btn height="54" value="DATE">Day</v-btn>
          <v-btn height="54" value="MONTH">Month</v-btn>
          <v-btn height="54" value="YEAR">Year</v-btn>
        </v-btn-toggle>
      </v-col>
      <v-spacer></v-spacer>
      <v-col v-if="showProductType" md="2">
        <v-select
            v-model="productTypeIds"
            :items="productTypes"
            :menu-props="{ bottom: true, offsetY: true }"
            background-color="#fff"
            item-text="name"
            item-value="id"
            label="Product Type"
            multiple
            outlined
            required
            @change="getCustomerGraphData"
            class="q-autocomplete shadow-0"
            hide-details
            dense
            style="max-width: 180px !important;"
        >
          <template
              v-if="productTypeIds.length == productTypes"
              v-slot:selection="{ index }"
          >
            <span v-if="index == 0">All</span>
          </template>
          <template v-else v-slot:selection="{ item, index }">
            <span v-if="index == 0">{{ item.name }}</span>
            <span v-if="index == 1">, ...</span>
          </template>
        </v-select>
      </v-col>
      <v-col v-if="showVenueService" md="2">
        <v-select
            v-model="venueServiceIds"
            :items="venueServices"
            :menu-props="{ bottom: true, offsetY: true }"
            background-color="#fff"
            item-text="name"
            item-value="id"
            multiple
            outlined
            required
            @change="getCustomerGraphData"
            class="q-autocomplete shadow-0"
            hide-details
            dense
            placeholder="Service"
        >
          <template
              v-if="venueServiceIds.length == venueServices"
              v-slot:selection="{ index }"
          >
            <span v-if="index == 0">All</span>
          </template>
          <template v-else v-slot:selection="{ item, index }">
            <span v-if="index == 0">{{ item.name }}</span>
            <span v-if="index == 1">, ...</span>
          </template>
        </v-select>
      </v-col>
    </v-row>


    <v-row>
      <v-col :cols="displayTopCustomers ? 6 : 12">
        <v-card class="shadow m-b-8 rounded-5" height="410px">
          <v-card-text>
            <v-row dense>
              <v-col cols="12">
                <div class="d-flex justify-space-between">
                  <SvgIcon text="Top Countries" class="gap-x-2 text-base font-semibold black-text">
                    <template v-slot:icon>
                      <FlagIcon/>
                    </template>
                  </SvgIcon>
                  <v-spacer/>
                  <v-btn-toggle v-if="showFilters"
                                v-model="dateToggle"
                                borderless
                                class="top-countries s"
                                mandatory
                  >
                    <v-btn value="DATE" class="text-capitalize">Day</v-btn>
                    <v-btn value="MONTH"  class="text-capitalize">Month</v-btn>
                    <v-btn value="YEAR"  class="text-capitalize">Year</v-btn>
                  </v-btn-toggle>

                  <v-btn
                      class="export-button mt-1"
                      elevation="0"
                      small
                      v-if="exportPermission && countryDataExist"
                      @click="exportPieData('country')"
                  >
                    <SvgIcon text="Export"  >
                      <template v-slot:icon>
                        <ExportIcon/>
                      </template>
                    </SvgIcon>
                  </v-btn>



                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5">
                <v-simple-table v-if="countryDataExist && countryPieChart" class="table">
                  <template v-slot:default>
                    <tbody>
                    <tr v-for="key in Object.keys(countryPieChart).slice(0,6)" :key="key" :class="key == Object.keys(countryPieChart).slice(0,6).length ? 'border-bottom':'' ">
                      <td class="font-medium">
                    <span v-if="nationalities.find(nat=>nat.name.toLowerCase() === key.toLowerCase())">
                        <SvgIcon
                            :icon="s3BucketURL + 'countries-flags/'+nationalities.find(nat=>nat.name.toLowerCase() === key.toLowerCase()).iso_2.toLowerCase()+'.svg'"
                            :text="key"
                            class="gap-x-2"
                            img-styles="width:30px;"
                        />
                    </span>
                        <span v-else>
                      {{ key }}
                    </span>
                      </td>
                      <td class="text-right p-r-4 font-medium">
                        {{ (countryPieChart[key] / (totalCustomerByCountry || 1) * 100) | numberFormatter(3) }}%
                      </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <div v-else class="d-flex fill-height align-center justify-center">
                  <div class="pb-4">No data exist</div>
                </div>
              </v-col>
              <v-col cols="7">

                <nationality-chart v-if="countryDataExist" :country-data="countryPieChart"/>
                <div v-else class="d-flex fill-height align-center justify-center">
                  <div class="pb-4">No data exist</div>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6" v-if="checkReadPermission($modules.clients.customers.slug) && displayTopCustomers">
        <v-card class="shadow m-b-8 rounded-5" height="410px">
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <div class="m-b-4 d-flex">
                  <SvgIcon :text="`Top ${displayTopPartnersOrCustomers}`" class="gap-x-2 text-base font-semibold black-text text-capitalize">
                    <template v-slot:icon>
                      <CustomerIcon/>
                    </template>
                  </SvgIcon>
                  <v-menu offset-y  v-if="checkReadPermission($modules.b2b.partner.slug) && !eventId">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" fab style="background: none !important;" small variant="plain" elevation="0">
                        <SvgIcon >
                          <template v-slot:icon>
                            <ArrowDownIcon/>
                          </template>
                        </SvgIcon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="getTopPartners" v-if="displayTopPartnersOrCustomers=='customers'  && checkReadPermission($modules.b2b.partner.slug)" >Top Partners</v-list-item>
                      <v-list-item @click="getTopCustomers" v-if="displayTopPartnersOrCustomers == 'partners'">Top Customers</v-list-item>
                    </v-list>
                  </v-menu>
                </div>
                <v-simple-table class="table" v-if="displayTopPartnersOrCustomers === 'partners' ">
                  <template v-slot:default>
                    <thead>
                    <tr class="tr-rounded" style="background-color: #E9F1F6">
                      <th class="text-left text-no-wrap black-text">Name</th>
                      <th class="text-left black-text">Members</th>
                      <th class="text-left black-text">Bookings</th>
                      <th class="text-left text-no-wrap black-text" >Sales</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in topPartners" :key="item.id">
                      <td class="text-no-wrap">
                    <span class="pointer">
                      {{ item.entity_name }}
                    </span>
                      </td>
                      <td>{{ item.customers.length  }}</td>

                      <td>
                        {{
                          Number(typeof item.bookings!= "undefined" ? item.bookings : 0)
                              | numberFormatter
                        }}</td>
                      <td>
                        {{
                          Number(typeof item.sales!= "undefined" ? item.sales : 0)
                              | numberFormatter
                        }}
                      </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <v-simple-table class="table" v-else>
                  <template v-slot:default>
                    <thead>
                    <tr class="tr-rounded" style="background-color: #E9F1F6">
                      <th class="text-left text-no-wrap black-text">Name</th>
                      <th class="text-left text-no-wrap black-text" >Visits</th>
                      <th class="text-left black-text">Gender</th>
                      <th class="text-left black-text">Nationality</th>
                      <th
                          class="text-left"
                          v-if="checkWritePermission($modules.clients.customers.id)"
                      >
                        Action
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in topCustomers" :key="item.id">
                      <td class="text-no-wrap">
                    <span class="pointer">
                      {{ item.first_name }} {{ item.last_name }}
                    </span>
                      </td>
                      <td>
                        {{
                          Number(typeof item.visits!= "undefined" ? item.visits : 0)
                              | numberFormatter
                        }}</td>


                      <td>{{ item.gender || "NA" }}</td>
                      <td>
                        <SvgIcon
                            :icon="s3BucketURL + 'countries-flags/'+item.iso_2.toLowerCase()+'.svg'"
                            :text="item.nationality"
                            v-if="item.nationality"
                            img-styles="width:30px;"
                        />
                        <template v-else>
                          N/A
                        </template>
                      </td>

                      <td
                          v-if="checkWritePermission($modules.clients.customers.id)"
                          class="md-table-cell"
                      >
                        <v-icon @click="editCustomer(item.id)" color="#66c8c8"
                        >mdi-pencil</v-icon
                        >
                      </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

    </v-row>


    <v-row>
      <v-col md="6" sm="12">
        <v-card class="shadow rounded-5" height="380px">
          <div class="d-flex justify-space-between p-5">
            <SvgIcon class="text-base font-semibold" text="Age">
              <template v-slot:icon>
                <AgeIcon/>
              </template>
            </SvgIcon>
            <v-btn
                v-if="exportPermission && ageDataExist"
                class="export-button"
                elevation="0"
                small
                @click="exportPieData('age')"
            >
              <SvgIcon text="Export">
                <template v-slot:icon>
                  <ExportIcon/>
                </template>
              </SvgIcon>
            </v-btn>
          </div>
          <pie-chart v-if="ageDataExist" :data="agePieChart"></pie-chart>
          <div v-else class="d-flex fill-height align-center justify-center">
            <div class="pb-4">No data exist</div>
          </div>
        </v-card>
      </v-col>
      <v-col md="6" sm="12">
        <v-card class="shadow rounded-5" height="380px">
          <div class="d-flex justify-space-between p-5">
            <SvgIcon class="text-base font-semibold" text="Gender">
              <template v-slot:icon>
                <GenderIcon/>
              </template>
            </SvgIcon>
            <v-btn
                v-if="exportPermission && genderDataExist"
                class="export-button"
                elevation="0"
                small
                @click="exportPieData('gender')"
            >
              <SvgIcon text="Export">
                <template v-slot:icon>
                  <ExportIcon/>
                </template>
              </SvgIcon>
            </v-btn>
          </div>
          <pie-chart v-if="genderDataExist" :data="genderPieChart"></pie-chart>
          <div v-else class="d-flex fill-height align-center justify-center">
            <div class="pb-4">No data exist</div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PieChart from "./PieChart";
import moment from "moment";
import AgeIcon from '@/assets/images/misc/age-icon.svg'
import GenderIcon from '@/assets/images/misc/gender-icon.svg'
import ExportIcon from '@/assets/images/misc/export-icon.svg'
import SvgIcon from "@/components/Image/SvgIcon.vue";
import NationalityChart from "@/components/NationalityChart.vue";
import CustomerIcon from "@/assets/images/misc/customer-icon.svg";
import FlagIcon from "@/assets/images/misc/flag-icon.svg";
import ArrowDownIcon from "@/assets/images/nav_icon/arrow-down.svg";
import DateRangeField from "@/components/Fields/DateRangeField.vue";

export default {
  components: {
    DateRangeField,
    ArrowDownIcon,
    CustomerIcon,
    NationalityChart,
    SvgIcon,
    PieChart,
    GenderIcon,
    AgeIcon,
    ExportIcon,
    FlagIcon,
  },
  props: {
    params: {
      type: Object,

      default: () => {
        return {
          venue_service_ids: [],
          product_ids: [],
          product_type_ids: [],
          b2b_id: null,
        };
      },
    },
    "export-permission": {type: Boolean, default: true},
    "show-venue-service": {type: Boolean, default: true},
    "show-product-type": {type: Boolean, default: false},
    "show-chart-headder": {type: Boolean, default: true},
    "stack-type": {type: String, default: "all"}, //service,type,all
    reload: {type: Boolean, default: false},
    eventId:{type:Number,default:null},
    membershipId:{type:Number,default:null},
    showFilters: {type: Boolean, default: false},
    displayTopCustomers: {type: Boolean, default: false}, // why? false
    searchParams: {
      type: Object,
      default: () => ({
        start_date: moment().startOf("year").format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD")
      })
    },
    refreshFlag:{type: Boolean, default:false},
  },
  watch: {
    refreshFlag:{
      immediate: true,
      handler(){
        this.getTopCustomers();
        this.getCustomerGraphData();
      }
    },
    params: {
      immediate: true,
      handler(params) {
        if (params.venue_service_ids.length > 0) {
          this.venueServiceIds = params.venue_service_ids;
        } else {
          this.venueServiceIds = [];
        }
        if (params.product_type_ids.length > 0) {
          this.productTypeIds = params.product_type_ids;
        } else {
          this.productTypeIds = [];
        }
        if (params.product_ids.length > 0) {
          this.productIds = params.product_ids;
        } else {
          this.productIds = [];
        }
        if (params.b2b_id && params.b2b_id != null) {
          this.b2b_id = params.b2b_id;
        }
        // this.getCustomerGraphData();
      },
    },

    reload: {
      immediate: true,
      handler(val) {
        if (val) {
          // this.getCustomerGraphData();
        }
      },
    },
    dateType: {
      immediate: true,
      handler(val) {
        if (val) {
          console.log(val);
        }
      },
    },
    dateToggle: {
      immediate: true,
      handler() {
        // this.getCustomerGraphData()
      }
    }
  },
  data() {
    return {
      agePieChart: {
        heading: "Age",
        chartData: [],
      },
      lineChartData:  [

      ],
      genderPieChart: null,
      countryPieChart: null,
      venueServiceIds: [],
      productTypeIds: [],
      b2b_id: null,
      productIds: [],
      date1: moment()
          .subtract(30, "days")
          .format("YYYY-MM-DD"),
      date2: moment().format("YYYY-MM-DD"),
      genderDataExist: true,
      countryDataExist: true,
      ageDataExist: true,
      totalCustomerByCountry: 0,
      dateToggle: "DATE",
      topCustomers: null,
      topPartners: null,
      displayTopPartnersOrCustomers:'customers',
    };
  },
  mounted() {
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
    if (this.$store.getters.getProductTypes.status == false) {
      this.$store.dispatch("loadProductTypes");
    }
    if (!this.$store.getters.getCountries.status) {
      this.$store.dispatch('loadCountries')
    }
    if(this.displayTopCustomers && this.displayTopPartnersOrCustomers == 'customers'){
      this.getTopCustomers();
    }

  },
  computed: {
    venueServices() {
      return this.$store.getters.getVenueServices.data;
    },
    productTypes() {
      return this.$store.getters.getProductTypes.data;
    },
    nationalities() {
      return this.$store.getters.getCountries.data;
    },
  },
  methods: {
    getTopCustomers() {
      this.displayTopPartnersOrCustomers = 'customers'
      if (
          this.searchParams &&
          this.searchParams.time_intervel != "All" &&
          this.searchParams.time_intervel != null
      ) {
        if (this.searchParams.time_intervel == "custom") {
          this.flag = true;
        } else if (this.searchParams.time_intervel == "day") {
          this.searchParams.start_date = moment().format("YYYY-MM-DD");
        } else if (this.searchParams.time_intervel == "week") {
          this.searchParams.start_date = moment()
              .startOf("week")
              .format("YYYY-MM-DD");
        } else if (this.searchParams.time_intervel == "month") {
          this.searchParams.start_date = moment()
              .startOf("month")
              .format("YYYY-MM-DD");
        } else if (this.searchParams.time_intervel == "year") {
          this.searchParams.start_date = moment()
              .startOf("year")
              .format("YYYY-MM-DD");
        }
      }
      let url = '';
      url += "?start_date=" + this.searchParams.start_date;
      url += "&end_date=" + this.searchParams.end_date;


      this.$http
          .get(`venues/customers/top-customers`+url,{
            params:{
              per_page:5,
              latest:true,
              event_id:this.eventId??undefined,
            }
          })
          .then((response) => {

            if (
                response.status == 200 &&
                response.data
            ) {
              this.topCustomers = response.data;
            }else{
              this.topCustomers = null;
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },

    getTopPartners() {
      this.displayTopPartnersOrCustomers = 'partners'
      this.$http
          .get(`venues/b2b?sort_by=top_sales&per_page=5`)
          .then((response) => {
            if (
                response.status == 200 &&
                response.data
            ) {
              this.topPartners = response.data.data;
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    dateChange(data) {
      this.date1 = data.date1;
      this.date2 = data.date2;
      // this.getCustomerGraphData();
    },
    getCustomerGraphData() {

      if (
          this.searchParams.time_intervel != "All" &&
          this.searchParams.time_intervel != null
      ) {
        if (this.searchParams.time_intervel == "custom") {
          this.flag = true;
        } else if (this.searchParams.time_intervel == "day") {
          this.searchParams.start_date = moment().format("YYYY-MM-DD");
        } else if (this.searchParams.time_intervel == "week") {
          this.searchParams.start_date = moment()
              .startOf("week")
              .format("YYYY-MM-DD");
        } else if (this.searchParams.time_intervel == "month") {
          this.searchParams.start_date = moment()
              .startOf("month")
              .format("YYYY-MM-DD");
        } else if (this.searchParams.time_intervel == "year") {
          this.searchParams.start_date = moment()
              .startOf("year")
              .format("YYYY-MM-DD");
        }
      }
      let url = `?date_type=${this.dateToggle.toLowerCase()}`;
      url += "&start_date=" + this.searchParams.start_date;
      url += "&end_date=" + this.searchParams.end_date;


      //
      // let url = `?date_type=${this.dateToggle.toLowerCase()}&start_date=${
      //     this.date1
      // }&end_date=${this.date2}`;

      if (
          this.venueServiceIds.length != this.venueServices.length &&
          this.venueServiceIds.length > 0
      ) {
        url += `&${this.venueServiceIds
            .map((n, index) => `venue_service_ids[${index}]=${n}`)
            .join("&")}`;
      }

      if (
          this.productTypeIds.length != this.productTypes.length &&
          this.productTypeIds.length > 0
      ) {
        url += `&${this.productTypeIds
            .map((n, index) => `product_type_ids[${index}]=${n}`)
            .join("&")}`;
      }

      if (this.productIds.length > 0) {
        url += `&${this.productIds
            .map((n, index) => `product_ids[${index}]=${n}`)
            .join("&")}`;
      }

      if (this.eventId) {
        url += `&event_id=${this.eventId}`;
      }
      if (this.membershipId) {
        url += `&membership_id=${this.membershipId}`;
      }
      let lastUrl = "";
      if (this.b2b_id && this.b2b_id != null) {
        url += `&b2b=${this.b2b_id}`;
        lastUrl = `venues/b2b/customers${url}`;
      } else {
        lastUrl = `venues/graphs/customers${url}`;
      }
      this.$http
          .get(lastUrl)
          .then((response) => {
            if (response.status == 200) {
              const data = response.data.data;
              this.genderPieChart = data.gender;
              this.totalCustomerByCountry = 0;
              Object.values(data.country).forEach(v => {
                this.totalCustomerByCountry += parseInt(v);
              })

              this.countryPieChart = data.country
              this.agePieChart = data.age
              // data.age.map(d=>{
              //   const gender = d.customer_gender;
              //   delete d.customer_gender;
              //   this.agePieChart.chartData.push(
              //       {
              //         data:d,
              //         name:gender
              //       }
              //   )
              // })

              this.ageDataExist = false;
              for (let key in this.agePieChart) {
                if (this.agePieChart[key]) {
                  this.ageDataExist = true;
                }
              }

              this.countryDataExist = false;
              for (let key in this.countryPieChart) {
                if (this.countryPieChart[key] > 0) {
                  this.countryDataExist = true;
                }
              }

              this.genderDataExist = false;
              for (let key in this.genderPieChart) {
                if (this.genderPieChart[key]) {
                  this.genderDataExist = true;
                }
              }
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    exportPieData(type) {
      let data = this[type + "PieChart"];
      let csvContent = "data:text/csv;charset=utf-8,Key,Count\r\n";
      for (let key in data) {
        let row = key + "," + (data[key] ? data[key] : 0) + "\r\n";
        csvContent += row;
      }
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", type + ".csv");
      document.body.appendChild(link);
      link.click();
    },
  },
};
</script>

<style lang="scss" scoped>
td {
  border-bottom: thin solid rgba(0, 0, 0, 0.05) !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.top-countries {
  .v-btn {
    &--active {
      background: #eff5f9 !important;
      color: #4FAEAF;
    }

    padding: 0.75rem !important;
    background: transparent !important;
    height: auto !important;
    border-radius: 0.5rem;
  }
}
</style>
