<template>
  <v-card class="rounded-lg shadow" v-if="topMembershipSales && topMembershipSales.length" style="min-height: 270px; cursor: pointer;    overflow: hidden; ">
    <div>
      <div class="p-3 bg-ghost-white d-flex justify-space-between align-center font-semibold">
        <h3 class="text-base font-semibold black-text">Top Memberships</h3>
      </div>
      <table class="dash_tbl membership-da-table">
        <tbody>
        <tr v-for="(topMembership,index) in topMembershipSales" :key="index" @click="viewMembership(topMembership.id)">
          <td class="text-left">{{ topMembership.name}}</td>
          <td class="text-right text-neon">{{ Number(typeof topMembership.sales != "undefined" ? topMembership.sales : 0) | toCurrency }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </v-card>
</template>
<script>
export default {
  props: {
    topMembershipSales: { type: Array, default: ()=>[]},
    defaultImages: { type: Object, default: ()=>{}},
  },
  methods: {
    viewMembership(id){
      this.$router.push({
        name: "MembershipView",
        params: {data: btoa(id)},
      });
    },
  }
}
</script>