<template>
  <pie-chart
    :data="data"
    :colors="colors"
    :library="chartOptions"
    legend="bottom"
  ></pie-chart>
</template>

<script>
import "@/utils/chart";
export default {
  props: {
    data: { type: Object, default: () => {} },
    colors: {
      type: Array,
      default: () => [
        "rgba(17, 42, 70, 1)",
        "rgba(79, 174, 175, 1)",
        "rgba(139, 217, 213, 1)",
        "rgba(67, 160, 197, 1)",
        "rgba(175, 224, 206, 1)",
        "rgba(138, 138, 138, 1)",
      ],
    },
  },
  data() {
    return {
      chartOptions: {
        tooltips: { enabled: true },
        plugins: {
          datalabels: {
            display: function (context) {
              return context.dataset.data[context.dataIndex] !== 0; // or >= 1 or ...
            },
            formatter: (value, ctx) => {
              let sum = 0;
              let dataArr = ctx.chart.data.datasets[0].data;
              dataArr.map((data) => {
                if (!isNaN(data)) {
                  sum += data;
                } else {
                  data = 0;
                  sum += 0;
                }
              });
              let percentage = "0%";
              if (sum > 0) {
                percentage = ((value * 100) / sum).toFixed(0) + "%";
              }
              return percentage;
            },
            anchor: "end",
            align: "start",
            color: "#fff",
            font: {
              size: 13,
              weight: 500,
            },
            offset: 8,
          },

        },
        legend:{
          labels:{
            boxWidth:16,
          }
        }
      },
    };
  },
};
</script>

<style></style>
