<template>
  <div class="d-flex gap-x-2 ">
    <div>
      <v-menu
          v-model="menu1"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          ref="menu1"
          min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
              :value="date1Formatted()"
              :dense="dense"
              :outlined="outlined"
              :solo="solo"
              readonly
              v-on="on"
              background-color="#fff"
              prepend-inner-icon="mdi-calendar"
              :class="className"
              hide-details
              style="max-width: 165px"
          >
            <template v-slot:prepend-inner>
              <CalenderIcon/>
            </template>
          </v-text-field>
        </template>
        <v-date-picker
            :active-picker.sync="activePicker"
            v-model="date1"
            @input="menu1 = false"
            @click:year="save1"
            @click:month="save1"
            @click:date="save1"
        ></v-date-picker>
      </v-menu>
    </div>
    <div>
      <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          ref="menu2"
          min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
              :value="date2Formatted()"
              :dense="dense"
              :outlined="outlined"
              :solo="solo"
              readonly
              v-on="on"
              background-color="#fff"
              prepend-inner-icon="mdi-calendar"
              :class="className"
              class="q-text-field shadow-0"
              validate-on-blur
              hide-details
              style="max-width: 165px"


          >
            <template v-slot:prepend-inner>
              <CalenderIcon/>
            </template>
          </v-text-field>
        </template>
        <v-date-picker
            :active-picker.sync="activePicker"
            v-model="date2"
            @input="menu2 = false"
            @click:year="save2"
            @click:month="save2"
            @click:date="save2"
        ></v-date-picker>
      </v-menu>
    </div>
   <div>
     <v-btn
         class=" white--text blue-color"
         color="darken-1"
         text
         small
         :height="dense ? 40 : 55"
         @click="goFunction"
     >Go</v-btn
     >
   </div>

  </div>
</template>

<script>
import moment from "moment";
import CalenderIcon from "@/assets/images/misc/calendar-icon.svg"

export default {
  components:{
    CalenderIcon
  },
  props: {
    value1: { type: String, default: null },
    value2: { type: String, default: null },
    filled: { type: Boolean, default: false },
    dense: { type: Boolean, default: true },
    dateType: { type: String, default: "DATE" },
    showLabel: { type: Boolean, default:false},
    hideDetails:{type:Boolean , default:false},
    solo:{type:Boolean , default:false},
    outlined:{type:Boolean , default:false},

    date1: {
      type: String,
      default: moment()
          .subtract(30, "days")
          .format("YYYY-MM-DD"),
    },
    date2: { type: String, default: moment().format("YYYY-MM-DD") },
    className: { type: String, default: "" },

  },
  watch: {
    menu1(val) {
      val && setTimeout(() => (this.activePicker = this.dateType));
    },
    menu2(val) {
      val && setTimeout(() => (this.activePicker = this.dateType));
    },
    dateType(val) {
      if (val == "YEAR") {
        this.date1 = moment()
            .subtract(1, "years")
            .format("YYYY-MM-DD");
        this.date2 = moment().format("YYYY-MM-DD");
      } else if (val == "MONTH") {
        this.date1 = moment()
            .subtract(0, "months")
            .format("YYYY-MM-DD");
        this.date2 = moment().format("YYYY-MM-DD");
      } else if (val == "DATE") {
        this.date1 = moment()
            .subtract(30, "days")
            .format("YYYY-MM-DD");
        this.date2 = moment().format("YYYY-MM-DD");
      }
      this.$emit("periodChange", { date1: this.date1, date2: this.date2 });
    },
  },
  data() {
    return {
      // date1: moment().subtract(30, "days").format("YYYY-MM-DD"),
      // date2: moment().format("YYYY-MM-DD"),
      menu1: false,
      menu2: false,
      activePicker: null,
    };
  },
  methods: {
    save2(date) {
      this.date2 = date.toString();
      this.$refs.menu2.save(date);
    },
    save1(date) {
      this.date1 = date.toString();
      this.$refs.menu1.save(date);
    },
    goFunction() {
      let date1 = this.date1;
      let date2 = this.date2;
      if (this.dateType == "MONTH") {
        date1 = this.date1.length > 7 ? this.date1 : this.date1 + "-01";
        date2 = this.date2.length > 7 ? this.date2 : this.date2 + "-28";
      } else if (this.dateType == "YEAR") {
        date1 = this.date1.length > 4 ? this.date1 : this.date1 + "-01-01";
        date2 = this.date2.length > 4 ? this.date2 : this.date2 + "-01-12-31";
      }
      this.$emit("periodChange", { date1: date1, date2: date2 });
      this.$emit("periodChange", { date1: date1, date2: date2 });
    },
    format() {
      if (this.dateType == "DATE") {
        return "Do MMM YYYY";
      } else if (this.dateType == "MONTH") {
        return "MMMM YYYY";
      }
      return "YYYY";
    },
    date1Formatted() {
      if (this.date1 == null) return null;
      return moment(this.date1, "YYYY-MM-DD").format(this.format());
    },
    date2Formatted() {
      if (this.date2 == null) return null;
      return moment(this.date2, "YYYY-MM-DD").format(this.format());
    },
  },
};
</script>

<style scoped>

</style>
