<template>
  <v-card class="rounded-lg shadow" v-if="topMembersSales && topMembersSales.length" style="min-height: 270px;overflow: hidden;">
    <div>
      <div class="p-3 bg-ghost-white d-flex justify-space-between align-center font-semibold">
        <h3 class="text-base font-semibold black-text">Top Members</h3>
      </div>
      <table class="dash_tbl membership-da-table">
        <tbody>
        <tr v-for="(topMember,index) in topMembersSales" :key="index">
          <td class="text-left">
                    <span>
                      <div class="gap-x-2 d-flex align-center gap-x-1">
                        <v-avatar size="40px" rounded="0" v-if="topMember.profile_image">
                          <img :src="topMember.profile_image ? s3BucketURL + topMember.profile_image : images[defaultImage]" />
                        </v-avatar>
                         <div v-else class="v-avatar text-h5 font-weight-light white--text rounded teal" style="height: 40px; min-width: 40px; width: 40px;">{{topMember.customer_name[0]}}</div>
                        <span> {{ topMember.customer_name }} </span>
                      </div>
                    </span>
          </td>
          <td class="text-right text-neon">{{ Number(typeof topMember.sales != "undefined" ? topMember.sales : 0) | toCurrency }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </v-card>
</template>
<script>
export default {
  props: {
    topMembersSales: { type: Array, default: ()=>[]},
    defaultImages: { type: Object, default: ()=>{}},
  },
}
</script>