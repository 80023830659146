<template>
  <v-card class="rounded-lg shadow" v-if="installmentDues && installmentDues.length" style="min-height: 270px;overflow: hidden;">
    <div>
      <div class="p-3 bg-ghost-white d-flex justify-space-between align-center font-semibold">
        <h3 class="text-base font-semibold black-text">Defaulter</h3>
      </div>
      <table class="dash_tbl membership-da-table">
        <tbody>
        <tr v-for="(duesMember,index) in installmentDues" :key="index">
          <td class="text-left">
                      <span>
                        <div class="gap-x-2 d-flex align-center gap-x-1">
                          <v-avatar size="40px" rounded="0" v-if="duesMember.profile_image">
                            <img :src="duesMember.profile_image ? s3BucketURL + duesMember.profile_image : images[defaultImage]" />
                          </v-avatar>
                           <div v-else class="v-avatar text-h5 font-weight-light white--text rounded teal" style="height: 40px; min-width: 40px; width: 40px;">{{duesMember.customer_name[0]}}</div>
                          <span> {{ duesMember.customer_name }} </span>
                        </div>
                      </span>
          </td>
          <td class="text-right red--text">{{ Number(typeof duesMember.dues != "undefined" ? duesMember.dues : 0) | toCurrency }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </v-card>
</template>
<script>
export default {
  props: {
    installmentDues: { type: Array, default: ()=>[]},
    defaultImages: { type: Object, default: ()=>{}},
  },
}
</script>